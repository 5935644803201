<template>
  <section class="app-section-root grid min-h-full bg-white grid-rows-app-section-root-layout dark:bg-primaryAppSectionBg shadow-app-section-root md:h-full md:min-h-[inherit] md:overflow-y-auto ">
    <div
      class="relative shadow-appSectionHeading flex items-center justify-between min-h-[50px] px-[20px] py-[10px] md:min-h-[59px] bg-white dark:bg-app-section-heading"
      :class="`${additionalsStyles}`"
    >
      <div class="app-section-title flex items-center font-medium leading-none text-black-300 dark:text-blue-500 text-[18px] md:text-[21px]">
        {{ title }}
      </div>
      <div class="md:m-0 md:self-center">
        <slot name="tollbar"></slot>
      </div>
    </div>
    <div class="px-[10px] pt-[10px] pb-[20px] md:overflow-auto md:p-[20px]">
      <slot name="content" />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    additionalsStyles: {
      type: String,
      default: ''
    }
  },
};
</script>

<style lang="scss" scoped>
@import './AppSection.scss';
</style>
